import './reverse'
import './vendor/makneil'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import * as Sentry from '@sentry/browser'
import {CaptureConsole, HttpClient} from '@sentry/integrations'

Sentry.init({
    dsn: 'https://8b9b0721bb52b3973fbe7bda4059a6f9@o445251.ingest.sentry.io/4505986140471296',
    integrations: [new CaptureConsole({levels: ['error']}), new HttpClient()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})

dayjs.locale('ru')
window['dayjs'] = dayjs
