import urls from './reverse.json'

/**
 * @param {string} name - url name
 * @param {Array|Object} params - url parameters
 */
function builder(name, params=null) {
    let pattern = name.split(':')
    let url = ''

    if (pattern.length === 1) {
        url = urls[pattern[0]]
    } else {
        url = urls[pattern[0]][pattern[1]]
    }

    if (typeof url !== 'string') {
        throw new Error('Url not found with the name "' + name + '"')
    }

    if (Array.isArray(params)) {
        for (const param of params) {
            url = url.replace(/%\(\w+\)s/, param)
        }
    }

    if (typeof params === 'object' && params !== null) {
        for (const key in params) {
            const value = params[key]
            url = url.replace('%(' + key + ')s', value)
        }
    }

    const check = url.match(/%\(\w+\)s/)
    if (check) {
        const name = check[0].replace(/%\(|\)s/g, '')
        throw new Error('The "' + name + '" parameter for url has not been defined.')
    }

    if (url.charAt(0) !== '/') {
        url = '/' + url
    }

    return url
}

window.reverse = builder